import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  white,
  darkGray,
  mobileSplitPoint,
  black,
} from '../../style-constants';

export const HeaderContainer = styled.div`
  background-color: ${white};
  border-bottom: 1px solid ${darkGray};
  display: flex;
  height: 70px;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: 70px;
    margin-bottom: 20px;
    padding: 0px;
  }
`;
HeaderContainer.displayName = 'HeaderContainer';

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
InnerContainer.displayName = 'InnerContainer';

export const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70px;
  padding: 25px;

  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 50px;
    padding-left: 35px;
  }
`;
LogoContainer.displayName = 'LogoContainer';

export const Logo = styled.img`
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 30px;
`;
Logo.displayName = 'Logo';

export const OptionsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
`;
OptionsContainer.displayName = 'OptionsContainer';

export const BusinessNameContainer = styled.div`
  font-size: 30px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    display: flex;
    flext-direction: row;
    justify-content: center;
    width: 50%;
  }
`;
BusinessNameContainer.displayName = 'BusinessNameContainer';

export const BusinessName = styled.div`
  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 19px;
    font-weight: 900;
    text-align: center;
  }
`;
BusinessName.displayName = 'BusinessName';

export const WholesaleLoginButton = styled.div`
  align-items: center;
  /* background-color: ${darkGray}; */
  /* border: 2px solid ${black}; */
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  width: 200px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    max-width: 150px;
    margin: 5px 0px;
    padding: 3px;
  }
`;
WholesaleLoginButton.displayName = 'WholesaleLoginButton';

export const WholesaleLoginText = styled.div`
  color: ${black};
  border-bottom: 2px solid ${black};
  font-size: 20px;
  font-weight: 700;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 18px;
  }
`;
WholesaleLoginText.displayName = 'WholesaleLoginText';
